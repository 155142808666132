.ql-align-right {
    text-align: right;
  }
   .ql-align-center {
    text-align: center;
  }
  .ql-font-serif
  {
    font-family: serif;
  }
  .ql-font-serif
  {
    font-family: serif;
  }
  .ql-font-monospace
  {
    font-family:monospace;
  }
